@font-face {
  font-family: "proxima_novaregular";
  src: url("./fonts/mark_simonson_-_proxima_nova-webfont.woff2") format("woff2"),
    url("./fonts/mark_simonson_-_proxima_nova-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima_nova_abold";
  src: url("./fonts/mark_simonson_-_proxima_nova_a_bold-webfont.woff2")
      format("woff2"),
    url("./fonts/mark_simonson_-_proxima_nova_a_bold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima_nova_asemibold";
  src: url("./fonts/mark_simonson_-_proxima_nova_a_semibold-webfont.woff2")
      format("woff2"),
    url("./fonts/mark_simonson_-_proxima_nova_a_semibold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "p22_mackinac_proextra_bold";
  src: url("./fonts/ihof_-_p22mackinacproextrabold-webfont.woff2")
      format("woff2"),
    url("./fonts/ihof_-_p22mackinacproextrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "inconsolatabold";
  src: url("./fonts/inconsolata-bold-webfont.woff2") format("woff2"),
    url("./fonts/inconsolata-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

@font-face {
  font-family: "inconsolataregular";
  src: url("./fonts/inconsolata-regular-webfont.woff2") format("woff2"),
    url("./fonts/inconsolata-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
// bootstrap color overrides
$theme-colors: (
  "primary": #7babe2,
  "secondary": #5270b1,
  "success": #5dbb46,
  "danger": #eb1c2d,
  "warning": #f36d25,
  "info": #279b48
);

// imports
@import "~bootstrap/scss/bootstrap";
@import "~react-table/react-table";

// color gradients
.bg-gradient-theme {
  background: linear-gradient(
    to bottom,
    theme-color("primary"),
    theme-color("secondary")
  );
}
.bg-gradient-theme-top {
  background: linear-gradient(
    to top,
    theme-color("primary"),
    theme-color("secondary")
  );
}
.bg-gradient-theme-left {
  background: linear-gradient(
    to left,
    theme-color("primary"),
    theme-color("secondary")
  );
}
.bg-gradient-theme-right {
  background: linear-gradient(
    to right,
    theme-color("primary"),
    theme-color("secondary")
  );
}

// css for spinner + checkbox on submit success
$loader-size: 7em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: theme-color("success");

.circle-loader {
  margin-bottom: $loader-size/2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;

  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: "";
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

:export {
  @each $key, $color in $theme-colors {
    #{$key}: $color;
  }
  @each $key, $color in $colors {
    #{$key}: $color;
  }
  // @each $key, $color in $grays {
  //   #{$key}: $color;
  // }
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(255, 255, 255, 1);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

.ripple-effect {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 55px;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);

  img {
    position: relative;
    font-size: 72px;
  }

  &::after {
    @include rings(3s, 0s);
  }

  &::before {
    @include rings(3s, 0.5s);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
